import { extend } from "webdriver-js-extender";

export interface MongoEntity {
  Id: string;
}

export interface Answer extends MongoEntity {
  Text: string;
  IsText: boolean;
  Value: number;
}

export interface Course {
  CourseId: number;
  CourseName: string;
  CourseCode: string;
}

export interface CourseSpec {
  StudyCycleId: number;
  RevisionId: number;

  Course: Course;
  Teacher: Teacher;
  Programme: Programme;
}

export interface Programme {
  ProgrammeId: number;
  ProgrammeName: string;
  ProgrammeCode: string;
}

export interface Question extends MongoEntity {
  new(): Question;

  Text: string;
  Type: number;

  Answers: Answer[];
}

export interface Schedule extends MongoEntity {
  SurveyId: string;
  FacultyId: number;
  DateFrom: Date | string;
  DateTo: Date | string;
  SemesterName: string;
  SemesterId: number;
  Type: number;//1-teacherId;2-all teachers
}

export interface StudentAnswer extends MongoEntity {
  SemesterName: string;
  SemesterId: number;
  IsTeacher: boolean;

  CourseSpec: CourseSpec;
  Answers: Answer[];
}

export interface StudentCourse extends MongoEntity {
  UserId: string;
  SemesterName: string;
  SemesterId: number;
  StudentCourseId: number;

  CourseSpec: CourseSpec;
}

export interface StudentSurvey extends MongoEntity {
  UserId: string;
  StudentCourseId: number;
  DateEvent: Date | string;
}

export interface Survey extends MongoEntity {
  new(): Survey;

  Name: string;
  FacultyId: number;

  Questions: Question[];
}

export interface SurveyCourse extends MongoEntity {
  SurveyId: string;
  SemesterName: string;
  SemesterId: number;
  IsTeacher: boolean;
  //Type: number;

  CourseSpec: CourseSpec;
}

export interface Teacher {
  TeacherId: number;
  TeacherName: string;
}

export interface Faculty {
  FacultyId: number;
  FacultyName: string;
}

export interface Semester {
  SemesterId: number;
  SemesterName: string;
}

export class ScheduleEntity implements Schedule {
  Id: string;
  SurveyId: string;
  FacultyId: number;
  DateFrom: Date | string;
  DateTo: Date | string;
  SemesterName: string;
  SemesterId: number;
  Type: number;//1-teacherId;2-all teachers

  //constructor(Id: string, SurveyId: string, FacultyId: number, DateFrom: Date | string, DateTo: Date | string, SemesterName: string, SemesterId: number, Type: number) {
  //  this.Id = Id;
  //  this.SurveyId = SurveyId;
  //  this.FacultyId = FacultyId;
  //  this.DateFrom = DateFrom;
  //  this.DateTo = DateTo;
  //  this.SemesterName = SemesterName;
  //  this.SemesterId = SemesterId;
  //  this.Type = Type;
  //}
}

export interface AnsweredSurveyEntity {
  AnsweredSurveys: number;
  SemesterName: string;
  AllSurveys: number;
}

export class SearchModel {
  sortExpression: string;
  sortDirection: string;
  pageIndex: number;
  pageSize: number;
}

export class GetAnswerDto {
  type: boolean;
  SemesterId: number;
  model: SearchModel;
}

export class TextualSearchModel {
  pageIndex: number;
  pageSize: number;
}

export class GetTextualAnswerDto {
  type: boolean;
  SemesterId: number;
  model: TextualSearchModel;
}
