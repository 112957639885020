import { Component, OnInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { SurveysService } from '../surveys/surveys.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit{

  roles: string[];

  claims: object;

  isFirst: boolean = true;

  constructor(private oauthService: OAuthService, private service: SurveysService) {
  }

  ngOnInit() {
    setTimeout(x => {
      this.claims = this.oauthService.getIdentityClaims();
      var logged = !(!this.claims || !this.oauthService.hasValidIdToken());

      if (logged && this.isFirst) {
        this.service.getUserRoles().subscribe(data => {
          this.roles = data.claimObject;
          this.isFirst = false;
        });
      }
    }, 1000);
  }


  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    this.oauthService.logOut();
  }

  get loginName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims || !this.oauthService.hasValidIdToken()) {
      return null;
    }
    return claims['Uname'] + ' ' + claims['Usurname'];
  }
}
