import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Survey, Schedule, GetAnswerDto, SearchModel, Semester, GetTextualAnswerDto, TextualSearchModel } from './../../shared/survey.models';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}api/Admin`;
  }

  private url: string;

  //getData(page, faculty) {
  //  return this.http.get(`${this.url}/SyncCourses?page=${page}&faculty=${faculty}`);
  //}

  changeCourseType(facultyId) {
    return this.http.get(`${this.url}/ChangeCourseType?facultyId=${facultyId}`);
  }

  getAnswers(smodel: GetAnswerDto) {
    if (smodel.model == null || smodel.model == undefined) {
      smodel.model = new SearchModel();
      smodel.model.pageIndex = 0;
      smodel.model.pageSize = 15;
      smodel.model.sortExpression = "CourseCode"
      smodel.model.sortDirection = "asc";
    }
    return this.http.post<any>(`${this.url}/GetNumericAnswers`, smodel, httpOptions);
  }

  getTextualAnswers(smodel: GetTextualAnswerDto) {
    if (smodel.model == null || smodel.model == undefined) {
      smodel.model = new TextualSearchModel();
      smodel.model.pageIndex = 0;
      smodel.model.pageSize = 15;
    }
    return this.http.post<any>(`${this.url}/GetTextualAnswers`, smodel, httpOptions);
  }

  getSemesters() {
    return this.http.get<Semester[]>(`${this.url}/GetSemesters`);
  }
}
