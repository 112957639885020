import { Component, OnInit } from '@angular/core';
import { StudentCourse } from '../shared/survey.models';
import { SurveysService } from './surveys.service';
import { StudentAnswersEntity } from '../survey-item/survey-item.models';

@Component({
  selector: 'app-surveys',
  templateUrl: './surveys.component.html',
  styleUrls: ['./surveys.component.css']
})
export class SurveysComponent implements OnInit {

  constructor(private service: SurveysService) { }

  ngOnInit() {
    this.getSurveysForStudent();
    this.getNumberOfAnsweredSurveysForStudent();
  }

  studentCourses: StudentCourse[] = [];

  StudentCoursesInfo: StudentAnswersEntity;

  isSyncCourses: boolean = false;
  isSyncInfo: boolean = false;

  getSurveysForStudent() {
    this.isSyncCourses = false;
    this.service.getSurveysForStudent().subscribe(x => {
      this.studentCourses = x;
      this.isSyncCourses = true;
    });
  }

  getNumberOfAnsweredSurveysForStudent() {
    this.isSyncInfo = false;
    this.service.getNumberOfAnsweredSurveys().subscribe(x => {
      this.StudentCoursesInfo = x;
      this.isSyncInfo = true;
    });
  }
}
