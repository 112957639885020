import { Component, OnInit } from '@angular/core';
import { ReportsService } from './reports.service';
import { Survey, Schedule, Semester } from './../../shared/survey.models';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {

  constructor(private service: ReportsService) { }

  //page: number = 0;

  facultyId: number = 0;

  semesters: Semester[];

  type: boolean = false;

  semester: number = -1;

  ngOnInit() {
    this.getSchedules();
  }

  getSchedules() {
    this.service.getSemesters().subscribe(data => {
      this.semesters = data;
    });
  }

  onChangeSemester() {
  }

  onChangeType() {
    this.semester = -1;
    console.log(this.type);
  }

  //syncData() {
  //  //this.page
  //  this.service.getData(this.page, 7).subscribe();
  //}

  //changeCourseType() {
  //  this.service.changeCourseType(this.facultyId).subscribe(x => console.log(x));
  //}

}
