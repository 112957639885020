import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AdminModule } from '../app/admin/admin.module';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import {MatSelectModule} from '@angular/material/select';
import { OAuthModule } from 'angular-oauth2-oidc';
import { Routing } from './app.routes';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AuthInterceptor } from './app.interceptor.service';
import { AuthGuard } from './app.guard.service';
import { ReactiveFormsModule } from '@angular/forms';

import {
  MatButtonModule, MatCheckboxModule,
  MatIconModule, MatMenuModule, MatSidenavModule,
  MatExpansionModule, MatListModule, MatToolbarModule, MatCardModule, MatRadioModule, MatInputModule,
} from '@angular/material';
import { LoginComponent } from './login/login.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SurveysService } from './surveys/surveys.service';
import { SurveyItemComponent } from './survey-item/survey-item.component';
import { QuestionsComponent } from './survey-item/questions/questions.component';
import { SurveyInfoComponent } from './survey-info/survey-info.component';
import { StudentCoursesInfoComponent } from './student-courses-info/student-courses-info.component';

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    SurveysComponent,
    LoginComponent,
    SurveysComponent,
    SurveyItemComponent,
    QuestionsComponent,
    SurveyInfoComponent,
    StudentCoursesInfoComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AdminModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule, MatCardModule,
    MatButtonModule, MatCheckboxModule, MatIconModule, MatMenuModule, MatSidenavModule, MatExpansionModule, MatListModule, MatRadioModule, MatInputModule, MatSelectModule, MatProgressSpinnerModule,
    OAuthModule.forRoot(),
    Routing
  ],
  providers: [
    SurveysService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
