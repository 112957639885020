import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { SurveyGlobalEntity, SelectedAnswerEntity, StudentAnswersEntity, Assistant } from './survey-item.models';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SurveyItemService {

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}api/SurveyItem`;
  }

  private url: string;

  getData(id) {
    return this.http.get<SurveyGlobalEntity>(`${this.url}/GetData?id=${id}`);
  }

  submitAnswers(data: StudentAnswersEntity) {
    //debugger;
    return this.http.post<any>(`${this.url}/SubmitAnswers`, data, httpOptions);
  }

  getAllAssistants(request: any) {
    return this.http.post<any>(`${this.url}/GetAllAssistants`, request, httpOptions);
  }

  syncSurveyCoursesWithNewAssistant(scitems: any) {
    let request = {
      list: scitems
    };
    return this.http.post<any>(`${this.url}/SyncSurveyCoursesWithNewAssistant`, request, httpOptions);
  }
}
