import { Component, OnInit, Input } from '@angular/core';
import { Teacher, Question, StudentCourse } from '../shared/survey.models';
import { SelectedAnswerEntity } from '../survey-item/survey-item.models';
import { forEach } from '@angular/router/src/utils/collection';

@Component({
  selector: 'app-survey-info',
  templateUrl: './survey-info.component.html',
  styleUrls: ['./survey-info.component.css']
})
export class SurveyInfoComponent implements OnInit {

  @Input()
  surveyName: string;

  @Input()
  studentCourse: StudentCourse;

  @Input()
  selectedTeachers: Teacher[];

  @Input()
  selectedAssistants: Teacher[];

  @Input()
  Questions: Question[];


  @Input()
  currentQuestion: number;

  constructor() { }

 
  ngOnInit() {
  }

}
