import { Component, OnInit, Input } from '@angular/core';
import { StudentAnswersEntity } from '../survey-item/survey-item.models';

@Component({
  selector: 'app-student-courses-info',
  templateUrl: './student-courses-info.component.html',
  styleUrls: ['./student-courses-info.component.css']
})
export class StudentCoursesInfoComponent implements OnInit {

  @Input()
  StudentCoursesInfo: StudentAnswersEntity;

  constructor() { }

  ngOnInit() {
  }

}
