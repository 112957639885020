import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { Schedule, Survey, Faculty, SearchModel } from '../../../shared/survey.models';
import { AdminService } from '../../admin.service';
import { MatPaginator, MatTableDataSource, MatSort } from '@angular/material';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';



@Component({
  selector: 'app-schedules-table',
  templateUrl: './schedules-table.component.html',
  styleUrls: ['./schedules-table.component.css']
})
export class SchedulesTableComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild(MatSort) sort: MatSort;

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 15;


  schedules: Schedule[];
  surveys: Survey[];

  faculties: Faculty[];

  filterCriteria: string;

  private searchModel: SearchModel;

  constructor(private adminService: AdminService) {
    this.searchModel = new SearchModel();
  };

  onPaginateChange(event) {
    this.searchModel.pageIndex = event.pageIndex;
    this.searchModel.pageSize = event.pageSize;
    //this.searchModel.sortDirection = this.sortDirection;
    //this.searchModel.sortExpression = this.sortExpresion;
    this.getSchedules(this.searchModel);
  }


  getSurveyName(id: string) {
    if (this.surveys) {
      let currentSurvey = this.surveys.find(s => s.Id == id);
      return currentSurvey.Name;
    }
  }

  getFacultyName(id: number) {
    if (this.faculties) {
      let f = this.faculties.find(x => x.FacultyId == id);
      return f.FacultyName;
    }
  }

  sortData(event) {
    this.searchModel.pageIndex = this.pageIndex;
    this.searchModel.pageSize = this.pageSize;
    this.searchModel.sortDirection =(event.direction != "") ? event.direction : "asc";
    this.searchModel.sortExpression = event.active;
    this.getSchedules(this.searchModel);
  }

  ngOnInit() {
    this.getSchedules(null).then(function () { });

    this.adminService.getSurveys().subscribe(data => this.surveys = data);
    this.adminService.getFaculties().subscribe(data => this.faculties = data);
  }


  async getSchedules(model) {
    await this.adminService.getSchedules(model).subscribe(data => {
      this.totalCount = data.total;
      this.schedules = data.Model;
      this.pageIndex = data.pageIndex;
      this.pageSize = data.pageSize;
    });
  }

  }
