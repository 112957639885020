import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { SurveysComponent } from './surveys/surveys.component';
import { SurveyItemComponent } from './survey-item/survey-item.component'; 
import { AuthGuard } from './app.guard.service';
import { AdministrationComponent } from './admin/administration/administration.component';
import { ReportsComponent } from './admin/reports/reports.component';
import { ScheduleFormComponent } from './admin/schedule-form/schedule-form.component';
import { CourseListComponent } from './admin/course-list/course-list.component'

const appRoutes: Routes = [
  { path: '', component: LoginComponent, pathMatch: 'full' },
  { path: 'surveys', component: SurveysComponent, canActivate: [AuthGuard], data: { permission: ["Студенти"] } },
  { path: 'survey/:id', component: SurveyItemComponent, canActivate: [AuthGuard], data: { permission: ["Студенти"] }},
  { path: 'admin/administration', component: AdministrationComponent, canActivate: [AuthGuard], data: { permission: ["Администратори"] } },
  { path: 'admin/reports', component: ReportsComponent, canActivate: [AuthGuard], data: { permission: ["Професори", "Асистенти"] } },
  { path: 'admin/schedule/new', component: ScheduleFormComponent, canActivate: [AuthGuard], data: { permission: ["Администратори"] } },
  { path: 'admin/schedule/:id', component: ScheduleFormComponent, canActivate: [AuthGuard], data: { permission: ["Администратори"] } },
  { path: 'admin/course-list', component: CourseListComponent, canActivate: [AuthGuard] }
];

export const Routing = RouterModule.forRoot(appRoutes);
