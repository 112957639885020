import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Faculty } from '../../shared/survey.models';

@Injectable({
  providedIn: 'root'
})
export class AdministrationService {

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}api/Admin`;
  }

  private url: string;

  getData(page, faculty) {
    return this.http.get(`${this.url}/SyncCourses?page=${page}&faculty=${faculty}`);
  }

  getFaculties() {
    return this.http.get<Faculty[]>(`${this.url}/GetFaculties`);
  }
}
