import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Teacher, Question, Answer, SurveyCourse } from '../../shared/survey.models';
import { SelectedAnswerEntity } from '../survey-item.models';
import { MatRadioChange } from '@angular/material';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  constructor() { }

  @Input() teacher: Teacher;
  @Input() question: Question;
  @Input() surveyCourses: SurveyCourse[]
  @Input() selectedAnswer: SelectedAnswerEntity;
  @Output() selectAnswer = new EventEmitter<SelectedAnswerEntity>();

  selectedItem: number;
  Text: string;

  ngOnInit() { this.initSelectedAnswer(); }

  //ngDoCheck() { }
  
  ngAfterContentChecked() {
    
    
    
  }

  initSelectedAnswer() {
    if (this.selectedAnswer != null) {
      this.selectedItem = this.selectedAnswer.SelectedValue;
      this.Text = this.selectedAnswer.TextValue;
    }
    else {
      this.selectedItem = null;
      this.Text = null;
    }
  }



  radioChange($event: MatRadioChange, answer: Answer) {
    //console.log(this.selectedItem);
    this.selectAnswer.emit({
      QuestionId: this.question.Id,
      AnswerId: answer.Id,
      SelectedValue: $event.value,
      TeacherId: this.teacher.TeacherId,
      TextValue: "",
      SurveyCourseId: null
    });
  }

  textChange(value:any, answer: Answer) {
    //console.log($event);
    this.selectAnswer.emit({
      QuestionId: this.question.Id,
      AnswerId: answer.Id,
      SelectedValue: 0,
      TeacherId: this.teacher.TeacherId,
      TextValue: value,
      SurveyCourseId: null
    });
  }

}
