import { Component, OnInit, AfterContentInit } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';
import { SurveysService } from '../surveys/surveys.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  constructor(private oauthService: OAuthService, private router: Router, private service: SurveysService) {
    //this.navigateOut();
  }

  isLoggedIn: boolean = true;
  claims: object;
  isFirst: boolean = true;

  roles: string[];

  ngOnInit() {
    setTimeout(x => {
      this.claims = this.oauthService.getIdentityClaims();
      let logged = !(!this.claims || !this.oauthService.hasValidIdToken());

      if (logged) {
        if (this.isFirst) {
          this.service.getUserRoles().subscribe(data => {
            localStorage.setItem('Roles', data.claimObject);
            this.roles = data.claimObject;
          });
          this.isFirst = false;
        }
        this.service.syncCourses().subscribe(x => {
          this.navigateOut();
        });
      } else {
        this.isLoggedIn = false;
        }
    }, 1000);
  }

  private navigateOut() {
    //TODO check role for navigation

    this.isLoggedIn = true;
    if (this.roles.includes("Студенти")) {
      this.router.navigate(['/surveys']);
    }
    else if (this.roles.includes("Професори") || this.roles.includes("Асистенти")) {
      this.router.navigate(['/admin/reports']);
    }
    else if (this.roles.includes("Администратори")) {
      this.router.navigate(['/admin/administration']);
    }
  }

  login() {
    this.oauthService.initImplicitFlow();
  }

  logout() {
    localStorage.removeItem('Roles');
    this.oauthService.logOut();
  }

  get givenName() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['name'];
  }
}
