import { Component, OnInit } from '@angular/core';
import { Schedule, Survey, Faculty, Semester, ScheduleEntity } from '../../shared/survey.models';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-schedule-form',
  templateUrl: './schedule-form.component.html',
  styleUrls: ['./schedule-form.component.css']
})
export class ScheduleFormComponent implements OnInit {

  surveys: Survey[];

  schedule: Schedule;

  result: string;

  minDate: Date;

  faculties: Faculty[];

  semesters: Semester[];

  scheduleForm: FormGroup;

  CtrlSurveyId: FormControl;
  CtrlFacultyId: FormControl;
  CtrlDateFrom: FormControl;
  CtrlDateTo: FormControl;
  CtrlSemesterId: FormControl;


  constructor(private adminService: AdminService, private route: ActivatedRoute, private router: Router, private formBuilder: FormBuilder) {
    this.result = this.route.snapshot.paramMap.get('id');
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate());

    if (this.result) {
      this.adminService.getScheduleById(this.result).subscribe(data => this.schedule = data);
    } else {
      this.schedule = new ScheduleEntity();
    }
    
  }


  onSubmit(isAdd: boolean) {
    if (isAdd) {
      this.addScheadule(this.schedule);
    }
    else {
      this.updateSchedule(this.schedule);
    }
  }

  addScheadule(newSchedule: Schedule) {
    let index = this.semesters.find(s => s.SemesterId == newSchedule.SemesterId);
    if (index != null) {
      newSchedule.SemesterName = index.SemesterName;
    }
    this.adminService.addNewSchedule(newSchedule).subscribe(x => {
      if (x) {
        setTimeout(() => this.router.navigate(['/admin/administration']), 500);
      }
    });
  }

  updateSchedule(newSchedule: Schedule) {
    let index = this.semesters.find(s => s.SemesterId == newSchedule.SemesterId);
    if (index != null) {
      newSchedule.SemesterName = index.SemesterName;
    }
    newSchedule.Id = this.result;
    this.adminService.updateSchedule(newSchedule).subscribe(x => {
      if (x) {
        setTimeout(() => this.router.navigate(['/admin/administration']), 500);
      }
    });
  }

  delete() {
    if (this.result) {
      this.adminService.deleteSchedule(this.result).subscribe(x => {
        if (x) {
          setTimeout(() => this.router.navigate(['/admin/administration']), 500);
        }
      });
    }
  }
  
  ngOnInit() {
    this.adminService.getSurveys().subscribe(data => this.surveys = data);
    this.adminService.getFaculties().subscribe(data => this.faculties = data);
    this.adminService.getSemesters().subscribe(data => this.semesters = data);
  }

}
