import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Schedule, Survey, Faculty, Semester, SearchModel } from '../shared/survey.models';
import { PageEvent } from '@angular/material';
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})

export class AdminService {

  url: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}api/Admin`;
  }

  getSchedules(model: SearchModel) {
    if (model == null || model == undefined) {
      model = new SearchModel();
      model.pageIndex = 0;
      model.pageSize = 15;
      model.sortExpression = "Id"
      model.sortDirection = "asc";
    }
    return this.http.post<any>(`${this.url}/GetSchedules`, model, httpOptions);
  }

  getSurveys() {
    return this.http.get<Survey[]>(`${this.url}/GetSurveys`);
  }

  getAllSchedules() {
    return this.http.get<Schedule[]>(`${this.url}/GetAllSchedules`);
  }

  getScheduleById(id: string) {
    return this.http.get<Schedule>(`${this.url}/GetScheduleById?id=${id}`);
  }

  addNewSchedule(newSchedule: Schedule) {
    //console.log(newSchedule);
    //const body = {
    //  SurveyId: (newSchedule.Id != null) ? newSchedule.SurveyId : "",
    //  FacultyId: (newSchedule.FacultyId != null) ? newSchedule.FacultyId : 0,
    //  DateFrom: (newSchedule.DateFrom != null) ? newSchedule.DateFrom : "",
    //  DateTo: (newSchedule.DateTo != null) ? newSchedule.DateTo : "",
    //  SemesterName: (newSchedule.SemesterName != null) ? newSchedule.SemesterName : "",
    //  SemesterId: (newSchedule.SurveyId != null) ? newSchedule.SemesterId : 0 
    //}
    return this.http.post<boolean>(`${this.url}/AddNewSchedule`, newSchedule, httpOptions);
  }

  updateSchedule(element: Schedule) {
    console.log(element);
    return this.http.post<boolean>(`${this.url}/UpdateSchedule`, element, httpOptions);
  }

  getFaculties() {
    return this.http.get<Faculty[]>(`${this.url}/GetFaculties`);
  }

  getSemesters() {
    return this.http.get<Semester[]>(`${this.url}/GetSemesters`);
  }

  deleteSchedule(id: string) {
    return this.http.get<any>(`${this.url}/DeleteSchedule?id=${id}`);
  }
}
