import { Component, OnInit, ViewChild } from '@angular/core';
import { Schedule, Faculty } from '../../shared/survey.models';
import { AdminService } from '../admin.service';
import { MatTableDataSource, MatPaginator, MatSort } from '@angular/material';
import { AdministrationService } from './administration.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.css']
})
export class AdministrationComponent implements OnInit {

  page: number = 0;

  faculty: number = 1;

  faculties: Faculty[];

  constructor(private service: AdministrationService) { }


  ngOnInit() {
    this.service.getFaculties().subscribe(data => this.faculties = data);
  }

  syncData() {
    this.service.getData(this.page, this.faculty).subscribe();
  }

}
