import { Component, OnInit, Input, AfterViewInit, OnChanges } from '@angular/core';
import { GetTextualAnswerDto, TextualSearchModel } from '../../../shared/survey.models';
import { ReportsService } from '../reports.service';

@Component({
  selector: 'app-textual-result',
  templateUrl: './textual-result.component.html',
  styleUrls: ['./textual-result.component.css']
})
export class TextualResultComponent implements OnInit, OnChanges {

  @Input()
  semesterId: number;

  @Input()
  AnswerType: boolean;

  searchmodel: GetTextualAnswerDto;

  totalCount: number = 0;
  pageIndex: number = 0;
  pageSize: number = 15;
  numFilledAnswers: number = 0;

  answers: any[];

  constructor(private service: ReportsService) {
    this.searchmodel = new GetTextualAnswerDto();
  }
  

  ngOnInit() {
    this.searchmodel.type = this.AnswerType;
    this.searchmodel.SemesterId = this.semesterId;
    this.searchmodel.model = null;
    this.getData(this.searchmodel);    
  }

  onPaginateChange(event) {
    this.searchmodel.type = this.AnswerType;
    this.searchmodel.SemesterId = this.semesterId;
    this.searchmodel.model.pageIndex = event.pageIndex;
    this.searchmodel.model.pageSize = event.pageSize;
    this.getData(this.searchmodel);
  }

  async getData(model) {
    await this.service.getTextualAnswers(model).subscribe(data => {
      this.answers = data.answers;
      this.totalCount = data.total;
      this.pageIndex = data.pageIndex;
      this.pageSize = data.pageSize;
      this.numFilledAnswers = data.numFilledAnswers;
    });
  }

  ngOnChanges(changes: any) {
    if (changes['semesterId'] || changes['AnswerType']) {
      this.searchmodel.model = new TextualSearchModel();
      this.searchmodel.type = this.AnswerType;
      this.searchmodel.SemesterId = this.semesterId;
      this.searchmodel.model.pageSize = this.pageSize;
      this.searchmodel.model.pageIndex = this.pageIndex;
      this.getData(this.searchmodel);
    }
  }

}
