import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { StudentCourse } from '../shared/survey.models';
import { StudentAnswersEntity } from '../survey-item/survey-item.models';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class SurveysService {

  constructor(private http: HttpClient, @Inject('BASE_URL') baseUrl: string) {
    this.url = `${baseUrl}api/Surveys`;
  }

  private url: string;

  getSurveysForStudent() {
    return this.http.get<StudentCourse[]>(`${this.url}/GetSurveysForStudent`);
  }

  syncCourses() {
    return this.http.get<boolean>(`${this.url}/SyncCourses`);
  }

  getNumberOfAnsweredSurveys() {
    return this.http.get<StudentAnswersEntity>(`${this.url}/GetNumberOfAnsweredSurveys`);
  }

  getUserRoles() {
    return this.http.get<any>(`${this.url}/GetUserRoles`);
  }

}
