import { Survey, StudentCourse, SurveyCourse } from "../shared/survey.models";

export interface SurveyGlobalEntity {
  Survey: Survey;
  StudentCourse: StudentCourse;
  SurveyCourses: SurveyCourse[];
}

export interface Assistant {
  Id: string;
  AssistantId: number;
  AssistantName: string;
}

export interface SelectedAnswerEntity {
  QuestionId: string;
  AnswerId: string;
  SelectedValue: number;
  TeacherId: number;
  TextValue: string;
  SurveyCourseId: string;
}

export class StudentAnswersEntity {
  StudentCourseId: string;
  StudentAnswers: SelectedAnswerEntity[];

  constructor(StudentCourseId: string, StudentAnswers: SelectedAnswerEntity[]) {
    this.StudentCourseId = StudentCourseId;
    this.StudentAnswers = StudentAnswers;
  }
}
