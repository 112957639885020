import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Survey, StudentCourse, SurveyCourse, Question, Teacher } from '../shared/survey.models';
import { SurveyItemService } from './survey-item.service';
import { SelectedAnswerEntity, StudentAnswersEntity } from './survey-item.models';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-survey-item',
  templateUrl: './survey-item.component.html',
  styleUrls: ['./survey-item.component.css'],
  //animations: [
  //  trigger('routerTransition', [
  //    state('hide', style({ opacity: 0 })),
  //    state('show', style({ opacity: 1 })),
  //    transition('hide => show', [
  //      animate(300)
  //    ])
  //  ])
  //]
})
export class SurveyItemComponent implements OnInit {

  constructor(private route: ActivatedRoute, private service: SurveyItemService, private oauthService: OAuthService, private router: Router) { }

  state = 'show';
  id: string;
  surveyItem: Survey;
  studentCourseItem: StudentCourse;
  surveyCourses: SurveyCourse[];

  currentIndex: number = 0;
  currentQuestion: Question;
  teachers: Teacher[] = [];
  assistants: Teacher[] = [];
  selectedAssistants: Teacher[] = [];
  selectedAnswers: SelectedAnswerEntity[] = [];

  isFirst: boolean;
  isLast: boolean;

  isAssistantsSync = false;

  canStart = false;

  facultyId: number = 0;

  canSave = true;

  isSubmitted = false;

  ngOnInit() {
    this.isFirst = true;
    this.isLast = false;
    this.id = this.route.snapshot.paramMap.get('id');
    //localStorage.setItem("surveyId", this.id);
    this.getData();
    this.facultyId = this.getCurrentFacultyId;
  }

  get getCurrentFacultyId() {
    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return null;
    }
    return claims['Fid'];
  }

  getData() {
    this.canStart = false;
    this.service.getData(this.id).subscribe(x => {
      this.surveyItem = x.Survey;
      this.studentCourseItem = x.StudentCourse;
      this.surveyCourses = x.SurveyCourses;

      this.currentQuestion = x.Survey.Questions[this.currentIndex];
      x.SurveyCourses.filter(z => z.IsTeacher).forEach(z => {
        this.teachers.push(z.CourseSpec.Teacher);
      });
      x.SurveyCourses.filter(z => !z.IsTeacher).forEach(y => {
        this.assistants.push(y.CourseSpec.Teacher);
      });
    });
    this.canStart = true;
  }

  getAssistants() {
    this.canStart = false;
    let surveyCourseId = this.surveyCourses[0].Id;
    let request = {
      id: surveyCourseId,
      listSurveyCourses: this.surveyCourses
    };
    this.service.getAllAssistants(request).subscribe(data => {
      if (data.length != 0) {
        data.filter(z => !z.IsTeacher).forEach(y => {
          this.assistants.push(y.CourseSpec.Teacher);
        });
        this.surveyCourses = [...this.surveyCourses, ...data];
      }
    });
    this.isAssistantsSync = true;
    this.canStart = true;
  }

  getSelected(t: Teacher) {
    //console.log(t);
    return this.selectedAnswers.find(x => x.QuestionId == this.currentQuestion.Id && x.TeacherId == t.TeacherId);
  }

  getQuestions() {
    if (this.surveyItem.Questions != null) return this.surveyItem.Questions;
  }

  answerChanged(item: SelectedAnswerEntity) {
    let inx = this.selectedAnswers.findIndex(x => x.QuestionId == item.QuestionId && x.TeacherId == item.TeacherId);
    if (inx >= 0) {
      this.selectedAnswers.splice(inx, 1);
    }

    let scItem = this.surveyCourses.find(x => x.CourseSpec.Teacher.TeacherId == item.TeacherId);
    item.SurveyCourseId = scItem.Id;
    //console.log(item);
    this.selectedAnswers.push(item);

    //console.log(this.selectedAnswers);
  }

  goNext() {
    if (this.hasNext()) {
      this.currentIndex++;
      this.currentQuestion = this.surveyItem.Questions[this.currentIndex];
      //console.log(this.currentQuestion.Id);
    }
    else {
      if (this.facultyId == 1) {
        let request = [];
        this.selectedAssistants.forEach(element => {
          let newItem = this.surveyCourses.find(scitem => scitem.CourseSpec.Teacher.TeacherId == element.TeacherId);
          request.push(newItem);
        });
        this.canSave = false;
        this.service.syncSurveyCoursesWithNewAssistant(request).subscribe(data => {
          data.forEach(element => {
            let surveyCourseItem = this.surveyCourses.find(scitem => scitem.CourseSpec.Teacher.TeacherId == element.CourseSpec.Teacher.TeacherId);
            surveyCourseItem.Id = element.Id;
            let selectedAnswerItem = this.selectedAnswers.filter(saitem => saitem.TeacherId == element.CourseSpec.Teacher.TeacherId);
            selectedAnswerItem.forEach(saitem => {
              saitem.SurveyCourseId = element.Id
            });
          });
          this.canSave = true;
        });
      }
      this.isLast = true;
    }
    this.state = 'hide';
  }

  hasNext() {
    return this.surveyItem.Questions.length-1 > this.currentIndex;
  }

  goBack() {
    if (this.hasBack()) {
      this.currentIndex--;
      this.currentQuestion = this.surveyItem.Questions[this.currentIndex];
    }
    else {
      this.isFirst = true;
    }
    this.state = 'hide';
  }

  hasBack() {
    return this.currentIndex > 0;
  }

  startWithSurvey() {
    this.isFirst = false;
    this.state = 'hide';
  }

  backToSurvey() {
    this.isLast = false;
    this.state = 'hide';
  }

  submitAnswers() {
    if (!this.isSubmitted && this.canSave) {
      this.isSubmitted = true;
      let model = new StudentAnswersEntity(this.studentCourseItem.Id, this.selectedAnswers);
      this.service.submitAnswers(model).subscribe(x => {
        if (x) {
          setTimeout(() => this.router.navigate(['/surveys']), 500);
        }
      });
    }
  }

  onDone() {
    this.state = 'show';
  }
}
