import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {


  constructor(private oauthService: OAuthService, private router: Router) {
    
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    const permission = route.data["permission"];

    var UserRoles = localStorage.getItem('Roles').split(",");

    if (this.oauthService.hasValidIdToken() && permission.some(r => UserRoles.includes(r))) {

      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

}
